import {NotificationCenterItemContainer} from '@rapid7/rapid7-ui';
import {useState} from 'react';

const TEXT = 'On February 5, 2025, we will upgrade the existing encryption protocols within the Security ' +
    'Console to improve our security posture. If you are a Nexpose or InsightVM customer currently operating in ' +
    'Federal Information Processing Standard (FIPS) mode, action will need to be taken prior to February 5th, to ' +
    'ensure authenticated scans are not interrupted.  If you are not operating in FIPS mode, no action is required at ' +
    'this time. Please follow the additional guidance and steps required outlined below, if you are in FIPs mode.';

const FedRampNotification = () => {
    const [showNotification, setShowNotification] = useState(() => {
        return !localStorage.getItem('fedRampNotificationRead');
    });

    if (!showNotification) {
        return null;
    }

    return (
        <NotificationCenterItemContainer
            key="fedRampNotification"
            includeIcon={false}
            notification={{ text: TEXT }}
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                window.open('https://docs.rapid7.com/nexpose/fips-mode-required-update-guide', '_blank');
            }}
            onDismiss={() => {
                localStorage.setItem('fedRampNotificationRead', 'true');
                setShowNotification(false);
            }}
        >
            <>
                {TEXT}
            </>
        </NotificationCenterItemContainer>
    );
};

export default FedRampNotification;
